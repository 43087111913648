<template>
  <!-- use Dashboard -->
  <section id="dashboard-ecommerce">
    <b-row v-if="_role == 'admin'">
      <b-col sm="3">
        <b-form-group>
          <h4>เลือก เดือน-ปี</h4>
          <v-select
            v-model="form.select"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :get-option-label="option => $date(option).format('MM-YYYY')"
            :clearable="false"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" v-if="!loadings.main">
        <BoxContainerLoading :loading="!loadings.main" />
      </b-col>

      <!-- <b-col sm="4">
        <DashboardSale />
      </b-col> -->
      <b-col sm="12" v-if="loadings.main">
        <b-row>
          <b-col sm="12">
            <DashboardTwo />
          </b-col>
          <b-col sm="12">
            <DashboardThree />
          </b-col>
          <b-col sm="12">
            <DashboardFour />
          </b-col>
          <!-- พี่ก๊อฟบอกว่าให้ปิดไว้ก่อนเพราะข้อมูลไปซ้ำกับ  <DashboardFour /> -->
          <!-- <b-col sm="12">
            <DashboardFive />
          </b-col> -->

          <b-col sm="12">
            <DashboardSix />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- <Partners v-else /> -->
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import vSelect from 'vue-select'
export default {
  watch: {
    'form.select'(value, old) {
      if (!this.loading) return ''
      if (!value) {
        this.form.select = old
        return ''
      }
      this.QUERY_RESULTS()
      return
    },
  },
  data() {
    return {
      selected: '',
    }
  },
  computed: {
    ...mapState('dashboardDataStore', ['options', 'form', 'loading', 'loadings']),
    _role() {
      return JSON.parse(localStorage.getItem('userData')).role
    },
  },
  components: {
    DashboardSale: () => import('./Components/DashboardSale'),
    DashboardTwo: () => import('./Components/DashboardTwo'),
    DashboardThree: () => import('./Components/DashboardThree'),
    DashboardFour: () => import('./Components/DashboardFour'),
    DashboardFive: () => import('./Components/DashboardFive'),
    DashboardSix: () => import('./Components/DashboardSix'),
    Partners: () => import('./Components/Partners/index.vue'),
    vSelect,
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('dashboardDataStore', ['fetchData']),
    ...mapMutations('dashboardDataStore', ['QUERY_RESULTS']),
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '~@core/scss/vue/libs/chart-apex.scss';
</style>
